export function JumpToCode() {
  return (
    <>
      <path
        id="Rounded-Rectangle"
        fill="#41b0f6"
        fillRule="evenodd"
        stroke="none"
        d="M 0 23 C -0 26.865993 3.134007 30 7 30 L 23 30 C 26.865993 30 30 26.865993 30 23 L 30 7 C 30 3.134007 26.865993 0 23 0 L 7 0 C 3.134007 0 0 3.134007 0 7 Z"
      />
      <g id="Group-copy">
        <path
          id="Path"
          fill="#ffffff"
          stroke="none"
          d="M 9.515563 21.713169 C 9.022184 21.713169 8.622222 21.313208 8.622222 20.819828 L 8.622222 9.894272 C 8.626633 9.565954 8.810795 9.266516 9.101819 9.114473 C 9.392844 8.962433 9.74382 8.982294 10.015834 9.166199 L 17.63603 14.61111 C 17.869898 14.778908 18.008589 15.049112 18.008589 15.336949 C 18.008589 15.624787 17.869898 15.894991 17.63603 16.06279 L 10.015834 21.543434 C 9.870002 21.649155 9.695618 21.708321 9.515563 21.713169"
        />
        <path
          id="path1"
          fill="#ffffff"
          stroke="none"
          d="M 19.766647 9.103664 L 21.106659 9.103664 C 21.600039 9.103664 22 9.503628 22 9.997005 L 22 20.717094 C 22 21.210472 21.600039 21.610435 21.106659 21.610435 L 19.766647 21.610435 C 19.27327 21.610435 18.873308 21.210472 18.873308 20.717094 L 18.873308 9.997005 C 18.873308 9.503628 19.27327 9.103664 19.766647 9.103664 Z"
        />
      </g>
    </>
  )
}
