export function Chevron() {
  return (
    <path
      id="Path"
      fill="currentColor"
      stroke="none"
      d="M 16.800001 16 L 11.599999 10.800001 C 11.355556 10.555555 11.233334 10.244444 11.233334 9.866667 C 11.233334 9.488888 11.355556 9.177777 11.599999 8.933332 C 11.844445 8.688889 12.155556 8.566668 12.533333 8.566668 C 12.911111 8.566668 13.222222 8.688889 13.466667 8.933332 L 19.6 15.066668 C 19.733334 15.2 19.828001 15.344444 19.884001 15.5 C 19.940001 15.655556 19.967556 15.822222 19.966667 16 C 19.965778 16.177778 19.938223 16.344444 19.884001 16.5 C 19.829779 16.655556 19.735112 16.800001 19.6 16.933334 L 13.466667 23.066666 C 13.222222 23.311111 12.911111 23.433334 12.533333 23.433334 C 12.155556 23.433334 11.844445 23.311111 11.599999 23.066666 C 11.355556 22.822222 11.233334 22.511112 11.233334 22.133335 C 11.233334 21.755555 11.355556 21.444444 11.599999 21.200001 Z"
    />
  )
}
export default Chevron
