export function Faq() {
  return (
    <>
      <path
        id="Path"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.692308"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 23 12 C 23 18.07513 18.07513 22.999998 12 22.999998 C 5.924869 22.999998 1.000001 18.07513 1.000001 12 C 1.000001 5.924868 5.924869 1 12 1 C 18.07513 1 23 5.924868 23 12 Z"
      />
      <path
        id="path1"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.692308"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 9.461538 9.461538 C 9.461538 8.059586 10.598046 6.923077 12 6.923077 C 13.401955 6.923077 14.538462 8.059586 14.538462 9.461538 C 14.538462 10.863493 13.401955 12 12 12 L 12 13.692307"
      />
      <path
        id="path2"
        fill="currentColor"
        stroke="none"
        d="M 12 16.230768 C 11.299023 16.230768 10.730769 16.799023 10.730769 17.5 C 10.730769 18.200975 11.299023 18.76923 12 18.76923 C 12.700976 18.76923 13.26923 18.200975 13.26923 17.5 C 13.260116 16.802834 12.697166 16.239883 12 16.230768 Z"
      />
    </>
  )
}
