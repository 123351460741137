export function Record() {
  return (
    <path
      id="Path"
      fill="currentColor"
      stroke="none"
      d="M 12 1 C 5.924868 1 1 5.924868 1 12 C 1 18.07513 5.924868 23 12 23 C 18.075132 23 23 18.07513 23 12 C 22.993587 5.927526 18.072474 1.006413 12 1 M 12 21.307692 C 6.859503 21.307692 2.692308 17.140497 2.692308 12 C 2.692308 6.859503 6.859503 2.692308 12 2.692308 C 17.140497 2.692308 21.307692 6.859503 21.307692 12 C 21.301863 17.138081 17.138081 21.301863 12 21.307692 M 19.615385 12 C 19.615385 16.20586 16.20586 19.615385 12 19.615385 C 7.794139 19.615385 4.384615 16.20586 4.384615 12 C 4.384615 7.79414 7.794139 4.384615 12 4.384615 C 16.203928 4.38928 19.610722 7.796074 19.615385 12"
    />
  )
}
