export function ErrorIcon() {
  return (
    <>
      <path
        id="Path"
        fill="currentColor"
        stroke="none"
        d="M 22.276001 3.057333 C 22.026344 2.806768 21.687042 2.666153 21.333334 2.666666 L 10.666667 2.666666 C 10.312957 2.666153 9.973657 2.806768 9.724 3.057333 L 3.057333 9.724001 C 2.806769 9.973658 2.666154 10.312956 2.666667 10.666666 L 2.666667 21.333332 C 2.666667 21.688 2.806667 22.026669 3.057333 22.276001 L 9.724 28.942667 C 9.973657 29.193232 10.312957 29.333847 10.666667 29.333334 L 21.333334 29.333334 C 21.688002 29.333334 22.026667 29.193335 22.276001 28.942667 L 28.942667 22.276001 C 29.193232 22.026344 29.333847 21.687042 29.333334 21.333332 L 29.333334 10.666666 C 29.333847 10.312956 29.193232 9.973658 28.942667 9.724001 Z M 17.333334 22.666668 L 14.666667 22.666668 L 14.666667 20 L 17.333334 20 Z M 17.333334 17.333332 L 14.666667 17.333332 L 14.666667 9.333334 L 17.333334 9.333334 Z"
      />
    </>
  )
}
