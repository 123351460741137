export function BuildIcon() {
  return (
    <>
      <path
        id="Rounded-Rectangle-copy-4"
        fill="#48b2c7"
        fillRule="evenodd"
        stroke="none"
        d="M 0 23 C -0 26.865993 3.134007 30 7 30 L 23 30 C 26.865993 30 30 26.865993 30 23 L 30 7 C 30 3.134007 26.865993 0 23 0 L 7 0 C 3.134007 0 0 3.134007 0 7 Z"
      />
      <path
        id="IonMdBuild"
        fill="#ffffff"
        stroke="none"
        d="M 22.786499 20.077826 L 16.181705 13.430189 C 16.835045 11.752214 16.470888 9.777916 15.092807 8.392694 C 13.639752 6.932499 11.461955 6.639746 9.719718 7.443031 L 12.840036 10.584772 L 10.66224 12.77685 L 7.466948 9.635111 C 6.599399 11.384489 6.963555 13.576565 8.413039 15.03676 C 9.791121 16.425552 11.751139 16.789709 13.421972 16.132799 L 20.026766 22.780436 C 20.315948 23.073189 20.751509 23.073189 21.044262 22.780436 L 22.715097 21.102459 C 23.079252 20.806137 23.079252 20.295605 22.786499 20.077826 Z"
      />
    </>
  )
}
