export function ReplayAPIsIcon() {
  return (
    <g id="Group">
      <path
        id="Path"
        fill="currentColor"
        stroke="none"
        d="M 11.647896 5.557295 L 8.124284 3.503002 L 4.600662 1.448839 C 4.447841 1.359779 4.274487 1.312901 4.09804 1.312916 C 3.921594 1.312929 3.748258 1.359831 3.595452 1.448915 C 3.442644 1.537998 3.315748 1.666124 3.227513 1.820415 C 3.139279 1.974707 3.092824 2.149725 3.092799 2.32789 L 3.092799 10.545081 C 3.092784 10.723266 3.139224 10.898314 3.227449 11.052629 C 3.315673 11.206944 3.442567 11.335074 3.595387 11.424166 C 3.748208 11.513259 3.921562 11.560158 4.098025 11.560158 C 4.274487 11.560139 4.447846 11.51324 4.600662 11.424128 L 8.124284 9.36997 L 11.647896 7.315678 C 11.800796 7.226603 11.927739 7.098473 12.016028 6.944138 C 12.104299 6.789803 12.150796 6.614717 12.150796 6.436476 C 12.150796 6.258251 12.104299 6.083166 12.016028 5.928831 C 11.927739 5.774498 11.800796 5.646368 11.647896 5.557295 Z"
      />
      <path
        id="path1"
        fill="currentColor"
        stroke="none"
        d="M 11.647876 16.997232 L 8.124265 14.943053 L 4.600513 12.888894 C 4.447718 12.799784 4.274386 12.752866 4.097939 12.752847 C 3.921491 12.752828 3.748143 12.799726 3.595333 12.888799 C 3.442524 12.977874 3.315646 13.106004 3.227427 13.260318 C 3.139209 13.414616 3.092775 13.589643 3.092792 13.767809 L 3.092792 21.984941 C 3.092792 22.163204 3.139226 22.338215 3.227444 22.492565 C 3.315661 22.646729 3.442549 22.774878 3.59535 22.864008 C 3.748151 22.953138 3.921479 23 4.097924 23 C 4.274366 23 4.447701 22.953138 4.600513 22.864008 L 8.124265 20.809982 L 11.647876 18.755728 C 11.800756 18.666637 11.927739 18.538486 12.016009 18.384132 C 12.104279 18.229799 12.150777 18.054714 12.150777 17.876471 C 12.150777 17.698229 12.104279 17.523142 12.016009 17.36879 C 11.927739 17.214457 11.800756 17.086304 11.647876 16.997232 Z"
      />
      <path
        id="path2"
        fill="currentColor"
        stroke="none"
        d="M 21.69722 11.277369 L 18.173609 9.223076 L 14.649996 7.168898 C 14.497173 7.079805 14.323828 7.032887 14.147364 7.032887 C 13.970919 7.032887 13.797554 7.079786 13.644732 7.168879 C 13.491908 7.25795 13.365003 7.386101 13.27679 7.540417 C 13.188558 7.694731 13.142118 7.86978 13.142137 8.047965 L 13.142137 16.265135 C 13.142156 16.443302 13.188634 16.61833 13.276866 16.772625 C 13.365098 16.926903 13.491984 17.055033 13.644808 17.144125 C 13.797611 17.2332 13.970938 17.280117 14.147383 17.280117 C 14.323828 17.280136 14.497173 17.233257 14.649996 17.144201 L 18.173609 15.089909 L 21.69722 13.035731 C 21.850119 12.946676 21.977102 12.818527 22.065372 12.664193 C 22.153662 12.509859 22.200121 12.334773 22.200121 12.156549 C 22.200121 11.978327 22.153662 11.803241 22.065372 11.648907 C 21.977102 11.494573 21.850119 11.366422 21.69722 11.277369 Z"
      />
    </g>
  )
}

export default ReplayAPIsIcon
