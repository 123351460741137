export function PlaywrightIcon() {
  return (
    <>
      <path
        id="Path"
        fill="#2d4552"
        stroke="none"
        d="M 10.5458 17.541849 C 9.351493 17.880795 8.567869 18.475075 8.051702 19.068853 C 8.546123 18.636299 9.208391 18.239113 10.101622 17.985903 C 11.01535 17.726944 11.79485 17.728819 12.438995 17.853174 L 12.438995 17.349506 C 11.889459 17.299263 11.259562 17.339256 10.5458 17.541849 M 7.997086 13.307905 L 3.56155 14.476467 C 3.56155 14.476467 3.642287 14.5907 3.792013 14.74305 L 7.552907 13.751959 C 7.552907 13.751959 7.499541 14.438725 7.036741 15.053001 C 7.912224 14.390732 7.997086 13.307905 7.997086 13.307905 M 11.710113 23.732477 C 5.467868 25.413708 2.1654 18.179623 1.165561 14.424976 C 0.703635 12.691755 0.501918 11.379215 0.448176 10.532103 C 0.443064 10.455488 0.444109 10.378588 0.451301 10.302139 C 0.127603 10.321512 -0.027497 10.489859 0.003998 10.976406 C 0.057739 11.822895 0.259457 13.135435 0.721382 14.869156 C 1.720846 18.623299 5.02369 25.857384 11.265935 24.176155 C 12.624591 23.810089 13.645302 23.143448 14.411678 22.292336 C 13.705293 22.930233 12.821434 23.432526 11.710113 23.732477 M 12.882925 8.880493 L 12.882925 9.324797 L 15.331281 9.324797 C 15.281163 9.167448 15.230546 9.025721 15.180305 8.880493 Z"
      />
      <path
        id="path1"
        fill="#2d4552"
        stroke="none"
        d="M 15.878692 12.539278 C 16.979765 12.852104 17.562172 13.624104 17.869997 14.307245 L 19.097799 14.655939 C 19.097799 14.655939 18.930326 12.264824 16.76755 11.650547 C 14.74425 11.07564 13.4992 12.774742 13.347725 12.994581 C 13.93638 12.575274 14.795742 12.231955 15.878692 12.539278 M 25.651743 14.318243 C 23.62657 13.740837 22.382021 15.443436 22.232796 15.660152 C 22.821825 15.241344 23.680811 14.8979 24.763262 15.2066 C 25.862709 15.519798 26.44449 16.290926 26.753317 16.974566 L 27.982744 17.324509 C 27.982744 17.324509 27.812647 14.93302 25.651743 14.318243 M 24.43194 20.622604 L 14.218833 17.767437 C 14.218833 17.767437 14.329441 18.327972 14.753623 19.053856 L 23.352613 21.457718 C 24.060501 21.048161 24.43194 20.622604 24.43194 20.622604 M 17.351206 26.768364 C 9.264382 24.600214 10.241975 14.296621 11.550513 9.414158 C 12.089302 7.401981 12.643212 5.906473 13.102639 4.903885 C 12.828557 4.847519 12.60147 4.991869 12.377254 5.448046 C 11.889335 6.437138 11.265684 8.047752 10.662282 10.302389 C 9.354118 15.184853 8.376401 25.488194 16.462847 27.656347 C 20.274359 28.677433 23.243755 27.125557 25.457151 24.688198 C 23.356239 26.591141 20.67367 27.658096 17.351206 26.768364"
      />
      <path
        id="path2"
        fill="#e2574c"
        stroke="none"
        d="M 12.883049 21.317867 L 12.883049 19.238577 L 7.106105 20.876812 C 7.106105 20.876812 7.532911 18.396461 10.5458 17.541849 C 11.459529 17.28289 12.239153 17.284641 12.883049 17.408997 L 12.883049 8.880493 L 15.775709 8.880493 C 15.460759 7.907276 15.156058 7.158022 14.900099 6.637354 C 14.476793 5.77562 14.042863 6.346901 13.057771 7.170895 C 12.363883 7.750549 10.610291 8.987226 7.97134 9.698236 C 5.332515 10.409872 3.198984 10.221153 2.308877 10.066927 C 1.046955 9.849213 0.386936 9.572008 0.448676 10.531979 C 0.502418 11.378592 0.70401 12.691254 1.166061 14.42485 C 2.1654 18.179121 5.468368 25.413082 11.710613 23.731853 C 13.341102 23.292549 14.491916 22.424065 15.289662 21.317242 L 12.883049 21.317242 Z M 3.56105 14.476467 L 7.997086 13.307905 C 7.997086 13.307905 7.867857 15.01438 6.204875 15.45281 C 4.541393 15.890739 3.56105 14.476467 3.56105 14.476467"
      />
      <path
        id="path3"
        fill="#2ead33"
        stroke="none"
        d="M 29.578238 8.979227 C 28.425173 9.18132 25.658743 9.433153 22.239918 8.516802 C 18.820093 7.60095 16.551208 5.999208 15.652228 5.246328 C 14.37781 4.179001 13.817273 3.437119 13.265613 4.559189 C 12.777941 5.54878 12.154166 7.159395 11.550513 9.414158 C 10.242475 14.296621 9.264757 24.599838 17.351206 26.76799 C 25.43578 28.934265 29.73996 19.521904 31.048126 14.639067 C 31.651779 12.384804 31.916487 10.67783 31.989225 9.577131 C 32.072334 8.330332 31.215975 8.692274 29.57836 8.979227 M 13.331352 13.018703 C 13.331352 13.018703 14.605647 11.036646 16.76705 11.651047 C 18.929825 12.265324 19.097298 14.656313 19.097298 14.656313 Z M 18.607376 21.912647 C 14.805614 20.799076 14.219334 17.767437 14.219334 17.767437 L 24.43194 20.62273 C 24.43194 20.622227 22.370522 23.012344 18.607376 21.912521 M 22.218046 15.682274 C 22.218046 15.682274 23.490715 13.701717 25.651743 14.317743 C 27.812647 14.93302 27.982744 17.324009 27.982744 17.324009 Z"
      />
      <path
        id="path4"
        fill="#d65348"
        stroke="none"
        d="M 10.864249 19.811234 L 7.106105 20.876312 C 7.106105 20.876312 7.514288 18.55056 10.282844 17.62896 L 8.154936 9.642496 L 7.97109 9.698362 C 5.33214 10.409998 3.198734 10.221277 2.308627 10.067051 C 1.046705 9.849463 0.386561 9.572132 0.448301 10.532228 C 0.502043 11.378841 0.70376 12.69138 1.165686 14.424976 C 2.16515 18.179247 5.468118 25.413206 11.710238 23.731977 L 11.894084 23.674238 Z M 3.56105 14.476467 L 7.997086 13.307779 C 7.997086 13.307779 7.867857 15.014256 6.204875 15.452686 C 4.541393 15.890615 3.56105 14.476467 3.56105 14.476467"
      />
      <path
        id="path5"
        fill="#1d8d22"
        stroke="none"
        d="M 18.778851 21.95439 L 18.606878 21.912521 C 14.805114 20.79895 14.218833 17.767437 14.218833 17.767437 L 19.485111 19.23945 L 22.273163 8.525675 L 22.239418 8.516928 C 18.819719 7.60095 16.550833 5.999208 15.65173 5.246202 C 14.377435 4.178875 13.816774 3.437119 13.265113 4.559189 C 12.777941 5.54878 12.154166 7.159395 11.550513 9.414158 C 10.242475 14.296621 9.264757 24.599838 17.351206 26.767864 L 17.51693 26.805359 Z M 13.331352 13.018578 C 13.331352 13.018578 14.605647 11.03652 16.76705 11.650923 C 18.929825 12.2652 19.097298 14.656189 19.097298 14.656189 Z"
      />
      <path
        id="path6"
        fill="#c04b41"
        stroke="none"
        d="M 11.055718 19.756493 L 10.047882 20.04257 C 10.286093 21.384855 10.705775 22.673023 11.364419 23.810965 C 11.479151 23.785721 11.592883 23.763971 11.709614 23.731977 C 12.015688 23.64949 12.299519 23.547131 12.572724 23.43565 C 11.836593 22.343327 11.349796 21.085527 11.055843 19.756493 M 10.662408 10.302515 C 10.14449 12.235579 9.681065 15.018005 9.80867 17.808805 C 10.036882 17.709698 10.278094 17.617336 10.546051 17.541349 L 10.732521 17.499607 C 10.505057 14.518587 10.996728 11.480949 11.550639 9.414158 C 11.681953 8.923964 11.822497 8.436289 11.972197 7.951393 C 11.728293 8.10738 11.47928 8.25523 11.225567 8.394697 C 11.021361 9.025652 10.833578 9.661808 10.662408 10.302515"
      />
    </>
  )
}
