export function University() {
  return (
    <>
      <path
        id="Path"
        fill="currentColor"
        stroke="none"
        d="M 12 3.142857 L 1 8.657343 L 2.571429 9.69213 L 2.571429 19.642857 L 4.142857 20.428572 L 4.142857 10.697942 L 11.992732 15.714286 L 18.481947 11.518032 L 23 8.580343 Z"
      />
      <path
        id="path1"
        fill="currentColor"
        stroke="none"
        d="M 18.58674 12.571428 L 12 16.881464 L 5.347504 12.571428 L 4.928571 16.575085 C 5.714286 17.186714 10.428572 20.428572 12.000099 22 C 13.571428 20.428572 18.285715 17.189022 19.071428 16.576656 L 18.58674 12.571428 Z"
      />
    </>
  )
}
