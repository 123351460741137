export function BracketsCurlyIcon() {
  return (
    <>
      <path
        id="Rounded-Rectangle-copy-5"
        fill="#efb219"
        fillRule="evenodd"
        stroke="none"
        d="M 0 23 C -0 26.865993 3.134007 30 7 30 L 23 30 C 26.865993 30 30 26.865993 30 23 L 30 7 C 30 3.134007 26.865993 0 23 0 L 7 0 C 3.134007 0 0 3.134007 0 7 Z"
      />
      <path
        id="brackets"
        fill="#ffffff"
        stroke="none"
        d="M 9.144 14.3192 C 9.024917 14.567483 8.869922 14.796878 8.684 15 C 8.869922 15.203122 9.024917 15.432517 9.144 15.680799 C 9.56 16.5392 9.56 17.5464 9.56 18.52 C 9.56 20.5952 9.7072 21.08 11.16 21.08 C 11.690193 21.08 12.12 21.509808 12.12 22.040001 C 12.12 22.570192 11.690193 23 11.16 23 C 9.6288 23 8.584 22.448 8.056 21.3592 C 7.64 20.500799 7.64 19.493599 7.64 18.52 C 7.64 16.444799 7.4928 15.96 6.04 15.96 C 5.509807 15.96 5.08 15.530193 5.08 15 C 5.08 14.469807 5.509807 14.04 6.04 14.04 C 7.4928 14.04 7.64 13.555201 7.64 11.48 C 7.64 10.506401 7.64 9.499201 8.056 8.6408 C 8.584 7.552 9.6288 7 11.16 7 C 11.690193 7 12.12 7.429808 12.12 7.959999 C 12.12 8.490192 11.690193 8.92 11.16 8.92 C 9.7072 8.92 9.56 9.4048 9.56 11.48 C 9.56 12.4536 9.56 13.4608 9.144 14.3192 M 23.959999 14.04 C 22.5072 14.04 22.360001 13.555201 22.360001 11.48 C 22.360001 10.506401 22.360001 9.499201 21.944 8.6408 C 21.416 7.552 20.371201 7 18.84 7 C 18.309807 7 17.879999 7.429808 17.879999 7.959999 C 17.879999 8.490192 18.309807 8.92 18.84 8.92 C 20.292801 8.92 20.440001 9.4048 20.440001 11.48 C 20.440001 12.4536 20.440001 13.4608 20.855999 14.3192 C 20.976313 14.567737 21.132666 14.797144 21.32 15 C 21.134079 15.203122 20.979084 15.432517 20.860001 15.680799 C 20.440001 16.5392 20.440001 17.5464 20.440001 18.52 C 20.440001 20.5952 20.292801 21.08 18.84 21.08 C 18.309807 21.08 17.879999 21.509808 17.879999 22.040001 C 17.879999 22.570192 18.309807 23 18.84 23 C 20.371201 23 21.416 22.448 21.944 21.3592 C 22.360001 20.500799 22.360001 19.493599 22.360001 18.52 C 22.360001 16.444799 22.5072 15.96 23.959999 15.96 C 24.490192 15.96 24.92 15.530193 24.92 15 C 24.92 14.469807 24.490192 14.04 23.959999 14.04"
      />
    </>
  )
}
