export function TreeViewSolid() {
  return (
    <>
      <path
        id="Rounded-Rectangle"
        fill="#f02d5e"
        fillRule="evenodd"
        stroke="none"
        d="M 0 23 C -0 26.865993 3.134007 30 7 30 L 23 30 C 26.865993 30 30 26.865993 30 23 L 30 7 C 30 3.134007 26.865993 0 23 0 L 7 0 C 3.134007 0 0 3.134007 0 7 Z"
      />
      <g id="Group">
        <path
          id="Path"
          fill="#ffffff"
          stroke="none"
          d="M 11.5 19.009615 L 13.5 19.009615 C 13.776142 19.009615 14 19.242085 14 19.528847 L 14 21.60577 C 14 21.892532 13.776142 22.125 13.5 22.125 L 11.5 22.125 C 11.223858 22.125 11 21.892532 11 21.60577 L 11 19.528847 C 11 19.242085 11.223858 19.009615 11.5 19.009615 Z"
        />
        <path
          id="path1"
          fill="#ffffff"
          stroke="none"
          d="M 13.5 13.817308 L 11.5 13.817308 C 11.223858 13.817308 11 14.049775 11 14.336538 L 11 14.959616 L 8.9 14.959616 L 8.9 11.740385 L 9.5 11.740385 C 9.776142 11.740385 10 11.507917 10 11.221153 L 10 9.14423 C 10 8.857468 9.776142 8.625 9.5 8.625 L 7.5 8.625 C 7.223858 8.625 7 8.857468 7 9.14423 L 7 11.221153 C 7 11.507917 7.223858 11.740385 7.5 11.740385 L 8.1 11.740385 L 8.1 20.982693 L 11.5 20.982693 C 11.720914 20.982693 11.9 20.796719 11.9 20.567307 C 11.9 20.337898 11.720914 20.151924 11.5 20.151924 L 8.9 20.151924 L 8.9 15.790384 L 11 15.790384 L 11 16.413462 C 11 16.700226 11.223858 16.932693 11.5 16.932693 L 13.5 16.932693 C 13.776142 16.932693 14 16.700226 14 16.413462 L 14 14.336538 C 14 14.049775 13.776142 13.817308 13.5 13.817308"
        />
        <path
          id="path2"
          fill="#ffffff"
          stroke="none"
          d="M 22.5 9.663462 L 11 9.663462 L 11 10.701923 L 22.5 10.701923 C 22.776142 10.701923 23 10.469456 23 10.182692 C 23 9.895929 22.776142 9.663462 22.5 9.663462"
        />
        <path
          id="path3"
          fill="#ffffff"
          stroke="none"
          d="M 22.5 14.855769 L 15 14.855769 L 15 15.894231 L 22.5 15.894231 C 22.776142 15.894231 23 15.661763 23 15.375 C 23 15.088237 22.776142 14.855769 22.5 14.855769"
        />
        <path
          id="path4"
          fill="#ffffff"
          stroke="none"
          d="M 22.5 20.048077 L 15 20.048077 L 15 21.086538 L 22.5 21.086538 C 22.776142 21.086538 23 20.854071 23 20.567307 C 23 20.280544 22.776142 20.048077 22.5 20.048077"
        />
      </g>
    </>
  )
}
