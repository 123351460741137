export function Clipboard() {
  return (
    <path
      id="Path"
      fill="currentColor"
      stroke="none"
      d="M 12 24 C 11.266667 24 10.639112 23.739112 10.117333 23.217335 C 9.595555 22.695555 9.334222 22.067554 9.333333 21.333332 L 9.333333 5.333334 C 9.333333 4.6 9.594666 3.972445 10.117333 3.450666 C 10.64 2.928888 11.267555 2.667555 12 2.666666 L 24 2.666666 C 24.733334 2.666666 25.361334 2.927999 25.884001 3.450666 C 26.406666 3.973333 26.667555 4.600889 26.666666 5.333334 L 26.666666 21.333332 C 26.666666 22.066667 26.405778 22.694668 25.884001 23.217335 C 25.362223 23.74 24.734222 24.000889 24 24 Z M 12 21.333332 L 24 21.333332 L 24 5.333334 L 12 5.333334 Z M 6.666667 29.333334 C 5.933334 29.333334 5.305778 29.072445 4.784 28.550667 C 4.262222 28.028889 4.000889 27.400888 4 26.666666 L 4 8 L 6.666667 8 L 6.666667 26.666666 L 21.333334 26.666666 L 21.333334 29.333334 Z M 12 21.333332 L 12 5.333334 Z"
    />
  )
}
export default Clipboard
