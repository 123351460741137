export function FileIcon() {
  return (
    <path
      id="Path"
      fill="currentColor"
      stroke="none"
      d="M 18.666666 2.666666 L 8 2.666666 C 6.533333 2.666666 5.346667 3.866667 5.346667 5.333334 L 5.333333 26.666666 C 5.333333 28.133333 6.52 29.333334 7.986666 29.333334 L 24 29.333334 C 25.466667 29.333334 26.666666 28.133333 26.666666 26.666666 L 26.666666 10.666666 Z M 8 26.666666 L 8 5.333334 L 17.333334 5.333334 L 17.333334 12 L 24 12 L 24 26.666666 Z"
    />
  )
}
export default FileIcon
