export function WebdriverIOIcon() {
  return (
    <>
      <path
        id="Path"
        fill="#ea5908"
        stroke="none"
        d="M 2.5 0 C 1.836416 -0.001778 1.199498 0.261044 0.730272 0.73027 C 0.261045 1.199497 -0.001777 1.836414 0 2.5 L 0 29.5 C -0.001777 30.163586 0.261045 30.800503 0.730272 31.26973 C 1.199498 31.738956 1.836416 32.001778 2.5 32 L 29.5 32 C 30.163586 32.001778 30.800503 31.738956 31.26973 31.26973 C 31.738956 30.800503 32.001778 30.163586 32 29.5 L 32 2.5 C 32.001778 1.836414 31.738956 1.199497 31.26973 0.73027 C 30.800503 0.261044 30.163586 -0.001778 29.5 0 Z M 3 8 L 4 8 L 4 24 L 3 24 Z M 12.446667 8 L 13.5 8 L 7.053333 24 L 5.998667 24 Z M 21.5 8 C 25.91828 8 29.5 11.581722 29.5 16 C 29.5 20.418278 25.91828 24 21.5 24 C 17.081722 24 13.5 20.418278 13.5 16 C 13.5 11.581722 17.081722 8 21.5 8 M 21.5 9 C 17.633638 9.000368 14.499632 12.134972 14.5 16.001333 C 14.50037 19.867695 17.634972 23.001701 21.501333 23.001331 C 25.367693 23.000965 28.501701 19.86636 28.501333 16 C 28.500963 12.133638 25.366362 8.999632 21.5 9"
      />
    </>
  )
}
